import { useRouter } from "next/router";
import { useEffect } from "react";

import SectionLogin from "@/components/section/SectionLogin";
import { useAuth } from "@/context/auth";
import { MainLayout } from "@/layout/MainLayout";

export default function LoginPage() {
	const { isAuthenticated } = useAuth();
	const router = useRouter();

	useEffect(() => {
		if (isAuthenticated) {
			router.push("/");
		}
	}, [isAuthenticated]);

	return (
		<MainLayout
			meta={{
				title: "Login",
				description: "Masuk untuk mendaftar program dan melakukan transaksi",
			}}
		>
			<SectionLogin />
		</MainLayout>
	);
}
