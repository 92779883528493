import Image from "next/image";

import FormLogin from "@/form/FormLogin";
import LogoIPBT from "@/public/img/logo/ipbt.png";

export default function SectionLogin() {
	return (
		<div className="relative h-[80vh] bg-cover bg-center">
			<Image
				src="/img/bg-xhdpi.png"
				alt="background"
				layout="fill"
				objectFit="cover"
        className="-z-10 object-cover"
			/>
			<div className="mt-[20vh] flex flex-col px-[10vw] xl:flex-row xl:px-[15vw]">
				<div className="my-12 hidden px-8 xs:flex xs:flex-col xl:my-auto xl:basis-1/2 xl:px-0">
					<h1 className="font-bold text-blue-ipbt xs:text-[2rem] xl:text-[3vw]">
						Selamat Datang
					</h1>
					<p className="text-gray-ipbt xs:text-[1.4rem] xl:text-[1vw]">
						di portal pendaftaran IPB Training
					</p>
				</div>
				<div className="mt-8 rounded-xl p-6 xs:mt-0 xl:basis-1/2 xl:border xl:border-white xl:shadow-md bg-white">
					<Image
						src={LogoIPBT}
						alt="category"
						sizes="100vw"
						style={{
							height: "auto",
							width: "40%",
						}}
						width={150}
						height={37}
						loading="lazy"
						quality={35}
						className="xl:mt-[1.5vw]"
					/>
					<FormLogin />
				</div>
			</div>
		</div>
	);
}
